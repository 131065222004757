<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="container main-workflow-container" v-if="Object.keys(workflow).length > 0">
      <div class="workflow-information">
        <div><strong>Title:</strong> {{ workflow.title }};</div>
        <div><strong>Platforms:</strong> {{ workflow.platforms.join(', ') }};</div>
        <div><strong>Application:</strong> {{ workflow.application }};</div>
        <div><strong>Type:</strong> {{ workflow.type }};</div>
      </div>
      <div class="actions sticky">
        <div class="blocks-container">
          <div v-for="(block, index) in blocks" :key="index" class="block-item" :style="{ backgroundColor: block.color }">
            <b-button v-on:click="addToWorkflow(block)" variant="outline-primary" class="add-btn">
              <b-icon icon="plus"></b-icon>
              <span>{{ block.label }}</span>
            </b-button>
          </div>
          <div class="block-item">
            <b-button @click="showBlocksLibrary = true" variant="outline-primary" class="add-btn" :style="{ backgroundColor: '#a0a1d5' }">
              <span>Blocks Library</span>
            </b-button>
          </div>
          <div class="block-item">
            <b-button @click="showScenarios = true" variant="outline-primary" class="add-btn" :style="{ backgroundColor: '#b0b1a5' }">
              <span>Scenarios ({{ workflow.scenarios.length }})</span>
            </b-button>
          </div>
        </div>
        <div class="test-button-group">
          <div class="status-count">
            <b-icon icon="check-circle" class="text-success"></b-icon> {{ successCount }}
            <b-icon icon="exclamation-circle" class="text-danger"></b-icon> {{ errorCount }}
          </div>
          <b-button v-on:click="testWorkflow()" :disabled="loading" variant="primary" class="test-btn">
            <span v-if="!loading">Test Workflow</span>
            <span v-else>Testing...</span>
          </b-button>
          <b-button v-on:click="toggleAllBlocks()" variant="outline-secondary" class="toggle-all-btn">
            <span v-if="allCollapsed">Expand All</span>
            <span v-else>Collapse All</span>
          </b-button>
          <div class="fullScreen" @click="fullscreen"><div></div><div></div></div>
        </div>
      </div>

      <div class="workflow-wrapper">
        <draggable
          v-model="workflow.blocks"
          class="workflow-container"
          :options="{ group: 'blocks', filter: '.cm-editor' }"
        >
          <transition-group>
            <b-row v-for="(block, index) in workflow.blocks" :key="index" class="workflow-item">
              <b-col>
                <div class="workflow-block">
                  <div class="block-header" :style="{ background: getBlockColor(block) }">
                    <span><strong>{{ block.title ? block.title : '' }}</strong> ({{ block.label }}) . Index: {{ index }}</span>
                    <div class="button-group">
                      <b-form-checkbox
                        :id="`checkbox-${index}`"
                        :name="`checkbox-${index}`"
                        v-model="block.checked"
                      >
                      </b-form-checkbox>
                      <b-icon
                        :icon="getStatusIcon(block)"
                        :class="getStatusClass(block)"
                        class="status-icon"
                      ></b-icon>
                      <b-button v-on:click="testWorkflow(block)" variant="outline-primary" class="restart-btn">
                        <b-icon icon="arrow-clockwise"></b-icon>
                      </b-button>
                      <b-button v-on:click="removeFromWorkflow(index)" variant="outline-danger" class="remove-btn">
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                      <b-button v-on:click="toggleBlock(block, index)" variant="outline-secondary" class="toggle-btn">
                        <b-icon :icon="block.collapsed ? 'chevron-down' : 'chevron-up'"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="block-body" v-if="!block.collapsed">
                    <b-form-group label="Title:">
                      <CodeMirrorComponent v-model="block.title" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                    </b-form-group>
                    <b-form-group label="Description:">
                      <CodeMirrorComponent v-model="block.description" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                    </b-form-group>
                    <div v-if="block.key === 'ai_block'" class="block-content">
                      <b-form-group label="Key:" v-if="block.content.mode === 'one_key_ai'">
                        <CodeMirrorComponent v-model="block.content.key" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Value:">
                        <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Mode:">
                        <b-form-radio v-model="block.content.mode" value="one_key_ai">One Key AI</b-form-radio>
                        <b-form-radio v-model="block.content.mode" value="full_data_ai">Full Data AI</b-form-radio>
                      </b-form-group>
                      <b-form-group label="Model:">
                        <b-form-select v-model="block.content.model" :options="['llama', 'gemma']"></b-form-select>
                      </b-form-group>
                      <b-form-group label="System Prompt:">
                        <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.system_prompt" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Temperature:">
                        <b-form-input class="border-top border-left border-right border-bottom rounded-0 bg-light mt-1 p-2" v-model="block.content.temperature" type="number" min="0" max="1" step="0.1"></b-form-input>
                      </b-form-group>
                    </div>
                    <div v-if="block.key === 'code_block'" class="block-content">
                      <b-form-group label="Code:">
                        <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                    </div>
                    <div v-if="block.key === 'field_block'" class="block-content">
                      <b-form-group label="Key:">
                        <CodeMirrorComponent v-model="block.content.key" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Value:">
                        <CodeMirrorComponent v-model="block.content.value" :options="block.library_block ? codeMirrorOptionsReadOnly : codeMirrorOptions" />
                      </b-form-group>
                    </div>
                    <div v-if="blockResult[block.uniqueId]" class="block-result">
                      <label>Result (Automate Data): </label>
                      <div class="result-content">
                        <div v-if="blockResult[block.uniqueId].error" class="error-message">{{ blockResult[block.uniqueId].error }}</div>
                        <pre v-else class="formatted-result">{{ formatJson(blockResult[block.uniqueId].result) }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </transition-group>
        </draggable>
        <div class="json-section sticky">
          <b-form-group label="Input JSON">
            <CodeMirrorComponent class="codemirror-json" v-model="inputJson" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Output JSON (Automate Data)">
            <CodeMirrorComponent class="codemirror-json" v-model="automateData" :options="codeMirrorOptions" />
          </b-form-group>
        </div>
      </div>
    </div>

    <b-modal v-model="showBlocksLibrary" title="Blocks Library" hide-footer>
      <div v-for="block in blocksLibrary" :key="block._id" class="block-item">
        <b-button v-on:click="addToWorkflowLibraryBlock(block)" variant="outline-primary" class="add-btn" :style="{ backgroundColor: getBlockColor(block) }">
          <b-icon icon="plus"></b-icon>
          <span>{{ block.title }}</span>
        </b-button>
      </div>
    </b-modal>

    <b-modal size="xl" v-model="showScenarios" title="Scenarios" hide-footer>
      <div v-for="(scenario, index) in workflow.scenarios" :key="index" class="scenario-item">
        <div class="scenario-header">
          <div>
            <label for="scenario-title">Title:</label>
            <b-input id="scenario-title" v-model="scenario.title" @input="editScenatio(index)" placeholder="Enter scenario title"></b-input>
          </div>
          <div>
            <label for="scenario-platforms">Platforms:</label>
            <b-form-select id="scenario-platforms" v-model="scenario.platforms" multiple @input="editScenatio(index)" :options="platforms"></b-form-select>
            <!-- <b-button variant="outline-secondary" @click="clearPlatforms(index)" class="ml-2">
              <b-icon icon="x-circle"></b-icon>
            </b-button> -->
          </div>
          <div>
            <label for="scenario-dates">Dates:</label>
            <date-picker id="scenario-dates" value-type="format" style="width: 100%;" @input="editScenatio(index)" v-model="scenario.dates" range></date-picker>
          </div>
        </div>
        <div class="scenario-content">
          <div class="scenario-blocks" v-if="scenario.blocks">
            Blocks: {{ scenario.blocks.map(block => block.title).join(', ') }}
          </div>
          <div class="totals mr-3" v-if="scenario.left">
            Left: {{ scenario.left }}
          </div>
          <b-button variant="primary" @click="runScenario(index)" :disabled="scenario.run" :class="['run-button', scenario.run ? 'disabled' : '']">
            <b-icon icon="play-fill"></b-icon>
          </b-button>
          <b-button variant="danger" @click="deleteScenario(index)" class="delete-button">
            <b-icon icon="trash-fill"></b-icon>
          </b-button>
        </div>
        <div v-if="scenario.logs && scenario.logs.length > 0" class="scenario-logs">
          <div v-for="(log, logIndex) in scenario.logs" :key="logIndex" :class="['scenario-log', log.type]">
            <div>
              <strong>Activity:</strong> {{ log.activityId }}
            </div>
            <div v-if="log.blockTitle">
              <strong>Block title:</strong> {{ log.blockTitle }}
            </div>
            <div>
              <strong>Result:</strong>
              <pre class="formatted-result">{{ log.result }}</pre>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Новая кнопка с абсолютным позиционированием -->
    <b-button class="floating-button" @click="logCheckedBlocks">
      <b-icon icon="plus"></b-icon>
    </b-button>
  </div>
</template>

<script>
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import draggable from 'vuedraggable'
import { mapGetters, mapMutations } from "vuex";
import CodeMirrorComponent from '@/agGridV2/components/codemirror.component.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { v4 as uuidv4 } from 'uuid'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'lead-center-workflow',
  components: {
    LeadCenterNavigation,
    draggable,
    CodeMirrorComponent,
    DatePicker
  },
  data() {
    return {
      blocks: [
        {
          id: 1,
          label: 'AI Block',
          key: 'ai_block',
          color: '#FAE7E5', // Gold
          content: {
            value: '',
            key: '',
            mode: 'one_key_ai',
            model: 'llama',
            system_prompt: '',
            temperature: 0.5
          },
          showGlobalSettings: false,
          collapsed: true
        },
        {
          id: 2,
          label: 'Code Block',
          key: 'code_block',
          color: '#D9E4FC', // Sky Blue
          content: {
            value: ''
          },
          collapsed: true
        },
        {
          id: 3,
          label: 'Field Block',
          key: 'field_block',
          color: '#FCF3C9', // Pale Green
          content: {
            value: '',
            key: ''
          },
          collapsed: true
        }
      ],
      workflow: {},
      blockStatus: {},
      blockResult: {},
      blocksResults: {},
      loading: false,
      inputJson: '',
      automateData: '',
      showGlobalSettings: false,
      codeMirrorOptions: {
        lineWrapping: true
      },
      codeMirrorOptionsReadOnly: {
        lineWrapping: true,
        readOnly: true
      },
      isSticky: false,
      initialActionsTop: null,
      allCollapsed: true,
      showBlocksLibrary: false,
      showScenarios: false,
      blocksLibrary: [],
      platforms: []
    }
  },
  async mounted() {
    this.setViewLoader(true)
    try {
      this.blocksLibrary = await MtApi.getBlocks()
      const platforms = await this.getPlatforms()
      this.platforms = platforms.filter((p) => p.url !== 'All').map((p) => {
        return {
          value: p._id,
          text: p.url
        }
      })
      this.workflow = await MtApi.getWorkflow(this.$route.params._id)
      if (Object.keys(this.workflow).length === 0) {
        this.$redirectTo('/lead-center/workflows')
      }
      this.workflow.blocks = this.workflow.blocks.map((block) => {
        return this.getBlockFromLibrary(block)
      })
      this.workflow.scenarios = this.workflow.scenarios || []
      if (this.workflow.scenarios.length > 0) {
        this.workflow.scenarios.forEach(scenario => {
          if (!scenario.platforms) {
            scenario.platforms = [];
          }
          if (!scenario.dates) {
            scenario.dates = [];
          }
        });
      }
      const timeout = setTimeout(() => {
        this.initialActionsTop = this.$el.querySelector('.actions').offsetTop
        window.addEventListener('scroll', this.handleScroll)
        clearTimeout(timeout)
      }, 1000)
    } catch (err) {
      console.log(err);
      this.$redirectTo('/lead-center/workflows')
    }
    this.setViewLoader(false)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll) // Clean up event listener
  },
  watch: {
    workflow: {
      handler: 'createOrUpdateConfig',
      deep: true
    },
    blocks: {
      handler: 'createOrUpdateConfig',
      deep: true
    }
  },
  computed: {
    successCount() {
      return this.workflow.blocks.filter((block) => this.blockStatus[block.uniqueId] === 'success').length
    },
    errorCount() {
      return this.workflow.blocks.filter((block) => this.blockStatus[block.uniqueId] === 'error').length
    },
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      }
    }
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform',
      'getPlatforms'
    ]),
    ...mapMutations(['setViewLoader']),
    handleScroll() {
      this.isSticky = window.scrollY > this.initialActionsTop
    },
    getBlockFromLibrary(block) {
      if (block.content.key !== 'library_block') {
        return block
      }
      const libraryBlock = this.blocksLibrary.find((bl) => bl._id === block.content.value)
      return {
        ...block,
        ...libraryBlock,
        library_block: true
      }
    },
    async createOrUpdateConfig() {
      await MtApi.createOrUpdateWorkflow({
        _id: this.workflow._id,
        blocks: this.workflow.blocks
      })
    },
    async addToWorkflow(block) {
      const newBlock = {
        ...block,
        uniqueId: uuidv4(),
        content: {
          ...JSON.parse(JSON.stringify(block.content))
        },
        showSettings: false,
        collapsed: true
      }
      this.workflow.blocks.push(newBlock)
    },
    async addToWorkflowLibraryBlock(block) {
      const newBlock = {
        ...block,
        label: `Library Block: ${block.key}`,
        uniqueId: block._id,
        content: {
          key: 'library_block',
          value: block._id
        },
        libraryBlock: true,
        showSettings: false,
        collapsed: true
      }
      this.workflow.blocks.push(newBlock)
    },
    async removeFromWorkflow(index) {
      this.workflow.blocks.splice(index, 1)
    },
    async testWorkflow(singleBlock = null) {
      this.loading = true
      if (singleBlock) {
        this.resetStatuses(singleBlock)
      } else {
        this.blocksResults = {}
        this.resetStatuses()
      }

      let activity_data
      try {
        activity_data = JSON.parse(this.inputJson)
      } catch (e) {
        alert('Invalid JSON input')
        this.loading = false
        return
      }

      const blocksToTest = singleBlock ? [singleBlock] : this.workflow.blocks

      let blockAnswer = null
      for (let i = 0; i < blocksToTest.length; i++) {
        if (
          blockAnswer &&
          blockAnswer['skipBlocks'] &&
          blockAnswer['skipBlocks'].includes(i)
        ) {
          continue
        }
        const block = blocksToTest[i]
        this.$set(this.blockStatus, block.uniqueId, 'testing')
        try {
          console.log(`${i} block request: `, {
            activity_data: activity_data,
            automate_data: Object.assign({}, ...Object.values(this.blocksResults)),
            block
          });
          const { result, prompt, system_prompt } = await this.processBlock(
            activity_data,
            Object.assign({}, ...Object.values(this.blocksResults)),
            block
          )
          blockAnswer = result
          console.log(`${i} block answer: `, blockAnswer);
          console.log(`${i} prompt: `, prompt);
          console.log(`${i} system_prompt: `, system_prompt);
          this.$set(this.blockStatus, block.uniqueId, 'success')
          if (
            blockAnswer &&
            blockAnswer['break']
          ) {
            delete blockAnswer['break']
            this.blocksResults[block.uniqueId] = {
              ...blockAnswer
            }
            this.$set(this.blockResult, block.uniqueId, { result: blockAnswer })
            break
          }
          this.blocksResults[block.uniqueId] = blockAnswer
          this.$set(this.blockResult, block.uniqueId, { result: blockAnswer })
        } catch (error) {
          this.$set(this.blockStatus, block.uniqueId, 'error')
          this.$set(this.blockResult, block.uniqueId, { error: error.response ? error.response.data.message : error.message })
        }
      }
      this.automateData = JSON.stringify(
        Object.assign({}, ...Object.values(this.blocksResults)),
        null,
        2
      )
      this.loading = false
    },
    resetStatuses(block = null) {
      if (block) {
        this.$set(this.blockStatus, block.uniqueId, null)
        this.$set(this.blockResult, block.uniqueId, null)
      } else {
        this.workflow.blocks.forEach((block) => {
          this.$set(this.blockStatus, block.uniqueId, null)
          this.$set(this.blockResult, block.uniqueId, null)
        })
      }
    },
    async processBlock(activity_data, automate_data, block) {
      return MtApi.workflowProcess({
        activity_data: activity_data,
        automate_data: automate_data,
        platform: this.selectedPlatform._id,
        block: {
          content: block.content,
          key: block.key
        }
      })
    },
    toggleGlobalSettings(block_id) {
      const blockIndex = this.blocks.findIndex((b) => b.id === block_id)
      this.$set(this.blocks[blockIndex], 'showGlobalSettings', !this.blocks[blockIndex].showGlobalSettings)
    },
    toggleBlock(block, index) {
      this.$set(this.workflow.blocks[index], 'collapsed', !block.collapsed)
    },
    toggleAllBlocks() {
      this.allCollapsed = !this.allCollapsed
      this.workflow.blocks.forEach(block => {
        this.$set(block, 'collapsed', this.allCollapsed)
      })
    },
    getStatusIcon(block) {
      const status = this.blockStatus[block.uniqueId]
      switch (status) {
        case 'testing':
          return 'clock'
        case 'success':
          return 'check-circle'
        case 'error':
          return 'exclamation-circle'
        default:
          return 'circle'
      }
    },
    getStatusClass(block) {
      const status = this.blockStatus[block.uniqueId]
      switch (status) {
        case 'testing':
          return 'text-primary'
        case 'success':
          return 'text-success'
        case 'error':
          return 'text-danger'
        default:
          return 'text-secondary'
      }
    },
    formatJson(json) {
      if (json) {
        //JSON.stringify(json, null, 2).trim()
        return JSON.stringify(json, null, 2)
      }
      return json
    },
    getBlockColor(block) {
      const originalBlock = this.blocks.find(b => b.key === block.key)
      return originalBlock ? originalBlock.color : '#FFFFFF'
    },
    fullscreen(event, init = false) {
      const toggleFullScreen = () => {
        this.$store.dispatch('toggleFullScreen', { page: this.$route.name })
        this.updateFullScreenClass()
      }

      const keyClose = () => {
        document.addEventListener('keydown', event => {
          if (event.code === 'Escape') {
            const isModal = document.querySelector('.vm--modal');
            if (!isModal) {
              toggleFullScreen()
            }
          }
        }, { once: true });
      }

      if (init) {
        const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
        if (isFullScreen) {
          this.updateFullScreenClass()
          keyClose()
        }
      } else {
        toggleFullScreen()
        keyClose()
      }
    },
    updateFullScreenClass() {
      const elements = document.getElementsByClassName('main-workflow-container')
      const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
      Array.from(elements).forEach((element) => {
        element.classList.toggle('main-workflow-container-fullScreen', isFullScreen)
      })
    },
    async logCheckedBlocks() {
      const checkedBlocks = this.workflow.blocks.filter(block => block.checked)
      this.workflow.scenarios.push({
        title: '',
        blocks: checkedBlocks,
        logs: [],
        run: false
      });
      await MtApi.createOrUpdateWorkflow({
        _id: this.workflow._id,
        scenarios: this.workflow.scenarios
      })
      this.workflow.blocks.forEach(block => {
        this.$set(block, 'checked', false)
      })
    },
    async editScenatio(index) {
      await MtApi.createOrUpdateWorkflow({
        _id: this.workflow._id,
        scenarios: this.workflow.scenarios
      })
    },
    async deleteScenario(index) {
      this.workflow.scenarios.splice(index, 1)
      await MtApi.createOrUpdateWorkflow({
        _id: this.workflow._id,
        scenarios: this.workflow.scenarios
      })
    },
    async runScenario(index) {
      try {
        this.workflow.scenarios[index].logs = []
        this.workflow.scenarios[index].run = true
        const scenario = this.workflow.scenarios[index]

        const filter = { platform: this.selectedPlatform._id };
        if (this.workflow.type) {
          filter['type'] = this.workflow.type
        }
        if (this.workflow.application) {
          filter['tech_data.application'] = this.workflow.application
        }
        console.log(filter);

        const activities = await MtApi.getActivitiesByFilter(filter);
        this.workflow.scenarios[index].left = activities.length;

        const processActivitiesInBatches = async (activities, scenario, index) => {
          const batchSize = 5;
          for (let i = 0; i < activities.length; i += batchSize) {
            const batch = activities.slice(i, i + batchSize);
            await processBatch(batch, scenario, index);
          }
          this.workflow.scenarios[index].run = false;
        }

        const processBatch = async (batch, scenario, index) => {
          await Promise.all(batch.map(async (activity) => {
            let automate_data = activity.automate_data;
            for (let i = 0; i < scenario.blocks.length; i++) {
              const block = scenario.blocks[i];
              try {
                const { result, prompt, system_prompt } = await this.processBlock(
                  activity,
                  activity?.automate_data || {},
                  block
                );

                automate_data = {
                  ...automate_data,
                  ...result
                }

                console.log('Result', result);

                const logIndex = scenario.logs.findIndex((log) => log.activityId === activity._id);
                if (logIndex !== -1) {
                  scenario.logs[logIndex].result = {
                    ...scenario.logs[logIndex].result,
                    ...result
                  };
                } else {
                  const logEntry = {
                    activityId: activity._id,
                    result: result,
                    type: 'success'
                  };
                  scenario.logs.push(logEntry);
                }
              } catch (err) {
                console.log(err, block);
                const logEntry = {
                  activityId: activity._id,
                  blockTitle: block.title,
                  result: err.message,
                  type: 'error'
                };
                scenario.logs.push(logEntry);
              }
            }
            // Update activities
            // await MtApi.updateActivity(activity._id, {
            //   event: 'update_activity',
            //   field: {
            //     name: `automate_data`,
            //     value: automate_data
            //   }
            // })
            this.workflow.scenarios[index].left--;
          }));
        }

        // Запуск обработки активностей
        processActivitiesInBatches(activities, scenario, index);
      } catch {
        this.workflow.scenarios[index].run = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.block-item {
  display: inline-flex;
  position: relative;
  margin-right: 25px;
  button {
    border: 0;
    color: #000000;
    border-radius: 0;
  }
}

.main-workflow-container {
  margin-bottom: 50px;

  .workflow-wrapper {
    display: flex;
    gap: 20px;
  }

  .blocks-container {
    display: flex;
  }

  .workflow-container {
    flex: 70%;
    width: 70%;
    border-radius: 4px;
    min-height: 200px;
    position: relative;
    pointer-events: none; /* Prevent drag and drop */
  }

  .workflow-item {
    margin-bottom: 10px;
  }

  .workflow-block {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    pointer-events: auto; /* Enable interactions within the block */
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .block-header {
    display: flex;
    cursor: grab;
    justify-content: space-between;
    align-items: center;
    color: #000000; /* Set header text color to white for contrast */
    background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(0,0,0,0.1)); /* Apply gradient */
    padding: 5px 10px;
    border-radius: 0;
  }

  .block-body {
    margin-top: 10px;
    background-color: #fff; /* Keep body white */
    padding: 10px;
    border-radius: 0 0 4px 4px;
  }

  .button-group {
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      border-radius: 0;
    }
    button:not(:hover) {
      background-color: #ffffff;
    }
  }

  .codemirror .cm-editor {
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    cursor: text;
  }

  .codemirror.codemirror-json .cm-editor {
    height: 215px;
  }

  .json-section {
    flex: 30%;
    width: 30%;
    border-radius: 4px;
    height: 580px;
    overflow-y: auto;
    position: sticky;
    top: 20px; /* Adjust the value as needed */
    z-index: 9; /* Ensure it's below the .actions section */
  }

  .json-section.sticky {
    top: 80px;
  }

  .status-icon {
    font-size: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s, border 0.3s;
    margin-bottom: 20px;
  }

  .status-count {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
    font-size: 1.2rem;
  }

  .workflow-wrapper {
    position: relative;
  }

  .block-result {
    margin: 12px;
    pointer-events: auto; /* Enable interactions within the result block */
    .result-content {
      background-color: #f8f9fa;
      border: 1px solid #ced4da;
      border-radius: 4px;
      .formatted-result {
        white-space: pre-wrap;
        word-break: break-word;
      }
      .error-message {
        color: #dc3545;
        font-weight: bold;
      }
    }
  }

  .test-button-group {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .settings-icon {
    cursor: pointer;
    top: 0;
    right: -17px;
    position: absolute;
    transform: translateY(-50%);
  }

  .global-settings-panel {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: #f8f9fa;
    .form-group {
      padding: 0;
      input {
        padding: 0 10px 0 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }
  }

  /* Styles for sticky state */
  .sticky {
    background-color: #ffffff;
    position: sticky;
    top: 0; /* Stick to the top of the container */
    z-index: 10;
  }

  .fullScreen {
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 18px;
    height: 18px;
    margin-right: 10px;

    position: relative;

    div {
      width: 100%;
      height: 100%;

      position: absolute;

      &::before,
      &::after {
        content: '';

        width: 5px;
        height: 5px;

        position: absolute;

        transition: 300ms ease;
      }

      &::before {
        border-left: 2px solid #7987FF;

        left: 0;
      }

      &::after {
        border-right: 2px solid #7987FF;

        right: 0;
      }

      &:first-child {
        &::before,
        &::after {
          border-top: 2px solid #7987FF;

          top: 0;
        }

        &::before {
          border-radius: 3px 0 0 0;
        }

        &::after {
          border-radius: 0 3px 0 0;
        }
      }

      &:last-child {
        &::before,
        &::after {
          border-bottom: 2px solid #7987FF;

          bottom: 0;
        }

        &::before {
          border-radius: 0 0 0 3px;
        }

        &::after {
          border-radius: 0 0 3px 0;
        }
      }
    }
  }
  .fullScreen:hover {
    div {
      &::before,
      &::after {
        width: 7px;
        height: 7px;
      }
    }
  }

  .main-workflow-container-fullScreen {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100% !important;
    max-width: initial !important;
    height: auto !important;
    margin: 0 !important;
    padding-top: 15px;
    padding-bottom: 75px !important;
  }
}

.workflow-information {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.floating-button:hover {
  background-color: #0056b3;
}

.scenario-item {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.scenario-header {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.scenario-content {
  display: flex;
  align-items: center;
}

.scenario-blocks {
  flex: 1;
  margin-right: 10px;
}

.scenario-block {
  margin-bottom: 5px;
}

.run-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.run-button .b-icon {
  font-size: 1rem;
}

hr {
  border: 0;
  height: 1px;
  background: #ccc;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.delete-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 10px;
}

.delete-button .b-icon {
  font-size: 1rem;
}

.scenario-logs {
  margin-top: 10px;
  height: 450px;
  overflow: auto
}

.scenario-log {
  margin-bottom: 10px;
}

.scenario-log:last-child{
  margin-bottom: 0;
}

.scenario-log.success {
  background-color: #a8eca8;
  padding: 5px;
}

.scenario-log.error {
  background-color: #ff8181;
  padding: 5px;
}

.formatted-result {
  white-space: pre-wrap;
  word-break: break-word;
}

input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 10px;
}

::v-deep {
  .mx-input {
    height: 38px;
  }
}

</style>
